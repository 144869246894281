import React, { useState } from 'react';
import './AddHabitPopup.css'; // Import CSS for styling

export function AddHabitPopup({ onAddHabit, onClose }) {
  const [habitName, setHabitName] = useState('');

  const handleSubmit = () => {
    if (habitName) {
      onAddHabit(habitName);
      setHabitName('');
      onClose();
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Add New Habit</h2>
        <input
          autoFocus
          type="text"
          placeholder="New Habit Name"
          value={habitName}
          onChange={(e) => setHabitName(e.target.value)}
        />
        <button onClick={handleSubmit}>Add Habit</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}
