import React, { useState } from 'react';
import './EditHabitDialog.css'; // Make sure to create the corresponding CSS for styling

export function EditHabitDialog({ habitName, habitID, onClose, onSave }) {
  const [newHabitName, setNewHabitName] = useState(habitName);

  const handleSave = () => {
    onSave(newHabitName);
  };

  return (
    <div className="edit-dialog-overlay">
      <div className="edit-dialog">
        <h2>Edit Habit</h2>
        <input
          autoFocus
          type="text"
          value={newHabitName}
          onChange={(e) => setNewHabitName(e.target.value)}
        />
        <div className="edit-dialog-buttons">
          <button onClick={handleSave} className="save-button">Save</button>
          <button onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
}