// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDKgdrk1dQsBmdzbk9RrnEg7pBLPVB_WQc",
  authDomain: "habits-ab5f4.firebaseapp.com",
  projectId: "habits-ab5f4",
  storageBucket: "habits-ab5f4.appspot.com",
  messagingSenderId: "1012643812923",
  appId: "1:1012643812923:web:e1e61b1a3a33c06f82ee1e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
//firebase login
//firebase init
//firebase deploy