// Import from libraries
import React, { useState, useEffect, useCallback } from "react";
import { signOut, signInWithPopup } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
// Import configs
import { db, auth, provider } from "../../config/firebase-config";
// Import CSS
import "./HabitTracker.css";
// Import images
import logo from "../../img/logo.png";
// Import components
import { DeleteConfirmationDialog } from "../../components/DeleteConfirmationDialog";
import { EditHabitDialog } from "../../components/EditHabitDialog";
import { AddHabitPopup } from "../../components/AddHabitPopup";
// Import hooks
import { useAddHabit } from "../../hooks/useAddHabit";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";

export function HabitTracker() {
  // State management
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [habits, setHabits] = useState([]);
  const [error, setError] = useState(null);
  console.log(error);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [habitNameToDelete, setHabitNameToDelete] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [habitNameToEdit, setHabitNameToEdit] = useState(null);
  const [idToEdit, setIDToEdit] = useState(null);
  const [showAddHabitPopup, setShowAddHabitPopup] = useState(false);
  const [showAllDates, setShowAllDates] = useState({}); // Track if all dates should be shown for each habit

  // Custom hooks
  const { addHabit } = useAddHabit();
  const { userID, name } = useGetUserInfo();

  // Navigation
  const navigate = useNavigate();

  // Check if the user is authenticated on component mount
  useEffect(() => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    if (authInfo && authInfo.isAuth) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Sign in with Google
  const signInWithGoogle = async () => {
    try {
      const results = await signInWithPopup(auth, provider);
      const authInfo = {
        userID: results.user.uid,
        name: results.user.displayName,
        isAuth: true,
      };
      localStorage.setItem("auth", JSON.stringify(authInfo));
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  // Sign out user
  const signUserOut = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      setIsAuthenticated(false);
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch habits from Firestore and use snaphot for real time monitoring
  const fetchHabits = useCallback(async () => {
    try {
      const habitsRef = collection(db, `users/${userID}/habits`);
      const habitsSnapshot = await getDocs(habitsRef);
      const habitsList = habitsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHabits(habitsList);
    } catch (error) {
      console.error("Error fetching habits:", error);
      setError("Failed to fetch habits. Please try again.");
    }
  }, [userID]);

  useEffect(() => {
    if (userID) {
      fetchHabits();
    }
  }, [userID, fetchHabits]);

  // Add a new habit with optimistic update
  const handleAddHabit = async (habitName) => {
    if (habitName) {
      // Optimistically update the UI
      const newHabit = { habitName, completedDates: {} };
      const originalHabits = [...habits];
      setHabits((prevHabits) => [...prevHabits, newHabit]);
      try {
        await addHabit({ habitName });
        fetchHabits();
        setError(null);
      } catch (error) {
        setError(error.message);
        // Revert UI update if error occurs
        setHabits(originalHabits);
      }
    }
  };

  // Delete a selected habit with optimistic update
  const handleDeleteHabit = async () => {
    if (habitNameToDelete && idToDelete) {
      // Optimistic update: remove the habit from the UI immediately
      const originalHabits = [...habits];
      setHabits(habits.filter((habit) => habit.id !== idToDelete));
      try {
        await deleteDoc(doc(db, `users/${userID}/habits`, idToDelete));
        setShowDeleteDialog(false);
      } catch (error) {
        console.error("Error deleting habit:", error);
        setError("Failed to delete habit. Please try again.");
        // Revert to original habits if deletion fails
        setHabits(originalHabits);
      }
    }
  };

  // Edit a selected habit's name with optimistic update
  const handleEditHabit = async (newHabitName) => {
    if (idToEdit && habitNameToEdit) {
      // Optimistic update: edit the habit name in the UI immediately
      const originalHabits = [...habits];
      const updatedHabits = habits.map((habit) =>
        habit.id === idToEdit ? { ...habit, habitName: newHabitName } : habit
      );
      setHabits(updatedHabits);

      try {
        await updateDoc(doc(db, `users/${userID}/habits`, idToEdit), {
          habitName: newHabitName,
        });
        setShowEditDialog(false);
      } catch (error) {
        console.error("Error updating habit:", error);
        setError("Failed to update habit. Please try again.");
        setHabits(originalHabits);
      }
    }
  };

  //Dialog functionality (for delete/edit)
  const openDeleteDialog = (h) => {
    setHabitNameToDelete(h.habitName);
    setIdToDelete(h.id);
    setShowDeleteDialog(true);
  };

  const openEditDialog = (h) => {
    setHabitNameToEdit(h.habitName);
    setIDToEdit(h.id);
    setShowEditDialog(true);
  };

  const closeDeleteDialog = () => {
    setHabitNameToDelete(null);
    setIdToDelete(null);
    setShowDeleteDialog(false);
  };

  const closeEditDialog = () => {
    setHabitNameToEdit(null);
    setIDToEdit(null);
    setShowEditDialog(false);
  };

  // Toggle habit completion for today
  const toggleHabitCompletion = async (habitID) => {
    const today = new Date().toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const habit = habits.find((h) => h.id === habitID);
    const isCompletedToday = habit?.completedDates[today];
    await markAsCompleted(habitID, today, !isCompletedToday);
  };

  // Mark habit for given date as completed or uncompleted with optimistic update
  const markAsCompleted = useCallback(
    async (habitID, date, isCompleted) => {
      const updatedHabits = habits.map((h) =>
        h.id === habitID
          ? {
              ...h,
              completedDates: { ...h.completedDates, [date]: isCompleted },
            }
          : h
      );
      setHabits(updatedHabits); // Optimistically update the UI

      try {
        const habitDocRef = doc(db, "users", userID, "habits", habitID);
        const habitDoc = await getDoc(habitDocRef);
        const habitData = habitDoc.data();

        if (habitData) {
          const updatedCompletedDates = {
            ...habitData.completedDates,
            [date]: isCompleted,
          };
          await setDoc(
            habitDocRef,
            { completedDates: updatedCompletedDates },
            { merge: true }
          );
        }
      } catch (error) {
        console.error("Error marking habit as completed:", error);
        setError("Failed to update habit. Please try again.");
        // Revert UI update if error occurs
        setHabits((prevHabits) =>
          prevHabits.map((h) =>
            h.habitID === habitID
              ? {
                  ...h,
                  completedDates: { ...h.completedDates, [date]: !isCompleted },
                }
              : h
          )
        );
      }
    },
    [userID, habits]
  );

  // Generate a date range between two dates
  const getDateRange = (start, end) => {
    const dates = [];
    const currentDate = new Date(start);
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Calculate current and longest streaks
  const calculateStreaks = (completedDates) => {
    if (!completedDates) return { currentStreak: 0, longestStreak: 0 };

    const completedDateEntries = Object.entries(completedDates).filter(
      ([_, value]) => value
    );
    if (completedDateEntries.length === 0)
      return { currentStreak: 0, longestStreak: 0 };

    const completedDatesSet = new Set(
      completedDateEntries.map(([date]) => date)
    );
    const today = new Date().toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const oldestDate = new Date(
      Math.min(
        ...completedDateEntries.map(([date]) => new Date(date).getTime())
      )
    );
    const dateRange = getDateRange(oldestDate, new Date());

    let currentStreak = 0;
    let longestStreak = 0;
    let tempStreak = 0;

    // Calculate streaks
    for (const date of dateRange) {
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      if (completedDatesSet.has(formattedDate)) {
        tempStreak += 1;
      } else {
        longestStreak = Math.max(longestStreak, tempStreak);
        tempStreak = 0;
      }
    }

    longestStreak = Math.max(longestStreak, tempStreak);
    currentStreak = completedDatesSet.has(today) ? tempStreak : 0;

    return { currentStreak, longestStreak };
  };

  const getLongestStreakDates = (completedDates) => {
    if (!completedDates) return [];
  
    const completedDateEntries = Object.entries(completedDates).filter(
      ([_, value]) => value
    );
    if (completedDateEntries.length === 0) return [];
  
    const completedDatesSet = new Set(
      completedDateEntries.map(([date]) => date)
    );
    const oldestDate = new Date(
      Math.min(...completedDateEntries.map(([date]) => new Date(date).getTime()))
    );
    const today = new Date();
    const dateRange = getDateRange(oldestDate, today);
  
    let longestStreak = 0;
    let tempStreak = 0;
    let currentStreakDates = [];
    let longestStreakDates = [];
  
    // Find longest streak
    for (const date of dateRange) {
      const formattedDate = date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
  
      if (completedDatesSet.has(formattedDate)) {
        tempStreak++;
        currentStreakDates.push(formattedDate);
      } else {
        if (tempStreak > longestStreak) {
          longestStreak = tempStreak;
          longestStreakDates = [...currentStreakDates];
        }
        tempStreak = 0;
        currentStreakDates = [];
      }
    }
    
    // Ensure to catch the case if the longest streak is at the end
    if (tempStreak > longestStreak) {
      longestStreakDates = [...currentStreakDates];
    }
  
    return longestStreakDates;
  };

  // Display completion dates
  const getCompletionDates = (completedDates, habitID) => {
    if (!completedDates) return "No dates yet";
  
    const today = new Date();
    const dates = Object.keys(completedDates).map((date) => new Date(date));
    const oldestDate = new Date(Math.min(...dates.map((date) => date.getTime())));
    const dateRange = getDateRange(oldestDate, today);
  
    const totalDates = dateRange.length; // Total number of date boxes
    const limit = showAllDates[habitID] ? totalDates : 60;
    const limitedDateRange = dateRange.slice(-limit);
  
    const longestStreakDates = getLongestStreakDates(completedDates);
  
    return (
      <div>
        {/* Only show the button if there are more than 60 date boxes */}
        {totalDates > 60 && (
          <div
          className="toggle-dates-button"
          onClick={() => toggleShowAllDates(habitID)}
          >{showAllDates[habitID] ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"/>
            </svg>
          : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-angle-expand" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707"/>
            </svg>
          }
          </div>
        )}
        {limitedDateRange.map((date) => {
          const formattedDate = date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
          const isCompleted = completedDates[formattedDate];
          const isLongestStreak = longestStreakDates.includes(formattedDate);
          const isFirstOfMonth = date.getDate() === 1;
  
          return (
            <div
              key={formattedDate}
              className={`date-box ${isCompleted ? "" : "missing-date"} ${
                isLongestStreak ? "longest-streak" : ""
              } ${isFirstOfMonth ? "new-month" : ""}`}
              title={formattedDate}
              onClick={() => markAsCompleted(habitID, formattedDate, !isCompleted)}
            ></div>
          );
        })}
      </div>
    );
  };
  

  // Toggle function to show all dates or just x number of date boxes
  const toggleShowAllDates = (habitID) => {
    setShowAllDates((prevState) => ({
      ...prevState,
      [habitID]: !prevState[habitID], // Toggle the state for the specific habit
    }));
  }; 

  // Render login page if user is not authenticated
  if (!isAuthenticated) {
    return (
      <div className="login-page">
        <div className="header">
          <div className="header-logo">
            <img alt="Logo" src={logo}></img>
          </div>
        </div>
        <p>Sign in with Google to continue</p>
        <div className="login-with-google-btn" onClick={signInWithGoogle}>
          Sign in with Google
        </div>
      </div>
    );
  }

  // Render habit tracker page
  return (
    <div>
      <div className="header">
        <div className="header-logo">
          <img alt="Logo" src={logo}></img>
        </div>
        <div className="header-menu">
          <div className="welcome-text">Welcome {name}!</div>
          <div className="menu-btn sign-out-button" onClick={signUserOut}>
            {" "}
            Log Out{" "}
          </div>
        </div>
      </div>

      <div className="habit-tracker">
        <ul>
          {habits
            // First, filter out the habits that are not completed today
            .filter((h) => {
              const today = new Date().toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              return !h.completedDates[today];
            })
            // Render the incomplete habits first
            .map((h) => {
              const { currentStreak, longestStreak } = calculateStreaks(
                h.completedDates
              );
              const today = new Date().toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              const isCompletedToday = h.completedDates[today];
              return (
                <li key={h.id} className="habit-item">
                  <div className="today-habit-checkbox">
                    <input
                      type="checkbox"
                      className="habit-checkbox"
                      id={h.id}
                      checked={isCompletedToday || false}
                      onChange={() => toggleHabitCompletion(h.id)}
                    />
                    <label className="cbx" htmlFor={h.id}>
                      <span>
                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                          <polyline points="1 5 4 8 11 1"></polyline>
                        </svg>
                      </span>
                      <span
                        className={`habit-text ${
                          isCompletedToday ? "completed" : ""
                        }`}
                      >
                        {h.habitName}
                      </span>
                    </label>
                  </div>
                  <div className="completion-dates">
                    {getCompletionDates(h.completedDates, h.id)}
                  </div>
                  <div className="habit-menu-bar">
                    <p>
                      Current{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-lightning-charge-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                      </svg>{" "}
                      {currentStreak} days &nbsp; &nbsp; Longest{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-lightning-charge-fill-longest"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                      </svg>{" "}
                      {longestStreak} days &nbsp; &nbsp;
                      <div
                        className="delete-btn"
                        onClick={() => openEditDialog(h)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                        </svg>
                      </div>{" "}
                      &nbsp; &nbsp;
                      <div
                        className="delete-btn"
                        onClick={() => openDeleteDialog(h)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi bi-trash3-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                        </svg>
                      </div>
                    </p>
                  </div>
                  <hr className="divider" />
                </li>
              );
            })}

          {habits
            // Then, filter out the habits that are completed today
            .filter((h) => {
              const today = new Date().toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              return h.completedDates[today];
            })
            // Render the completed habits afterwards
            .map((h) => {
              const { currentStreak, longestStreak } = calculateStreaks(
                h.completedDates
              );
              const today = new Date().toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              const isCompletedToday = h.completedDates[today];
              return (
                <li key={h.id} className="habit-item">
                  <div className="today-habit-checkbox">
                    <input
                      type="checkbox"
                      className="habit-checkbox"
                      id={h.id}
                      checked={isCompletedToday || false}
                      onChange={() => toggleHabitCompletion(h.id)}
                    />
                    <label className="cbx" htmlFor={h.id}>
                      <span>
                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                          <polyline points="1 5 4 8 11 1"></polyline>
                        </svg>
                      </span>
                      <span
                        className={`habit-text ${
                          isCompletedToday ? "completed" : ""
                        }`}
                      >
                        {h.habitName}
                      </span>
                    </label>
                  </div>
                  <div className="completion-dates">
                    {getCompletionDates(h.completedDates, h.id)}
                  </div>
                  <div className="habit-menu-bar">
                    <p>
                      Current{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-lightning-charge-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                      </svg>{" "}
                      {currentStreak} days &nbsp; &nbsp; Longest{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-lightning-charge-fill-longest"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                      </svg>{" "}
                      {longestStreak} days &nbsp; &nbsp;
                      <div
                        className="delete-btn"
                        onClick={() => openEditDialog(h)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                        </svg>
                      </div>{" "}
                      &nbsp; &nbsp;
                      <div
                        className="delete-btn"
                        onClick={() => openDeleteDialog(h)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi bi-trash3-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                        </svg>
                      </div>
                    </p>
                  </div>
                  <hr className="divider" />
                </li>
              );
            })}
          <li className="habit-item">
            <div className="add-section">
              <div
                className="add-btn"
                onClick={() => setShowAddHabitPopup(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-plus-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                </svg>
              </div>
              <div className="add-btn-label">New habit</div>
            </div>
          </li>
        </ul>
        {showDeleteDialog && (
          <DeleteConfirmationDialog
            habitName={habitNameToDelete}
            habitID={idToDelete}
            onDelete={handleDeleteHabit}
            onClose={closeDeleteDialog}
          />
        )}
        {showAddHabitPopup && (
          <AddHabitPopup
            onAddHabit={handleAddHabit}
            onClose={() => setShowAddHabitPopup(false)}
          />
        )}
        {showEditDialog && (
          <EditHabitDialog
            habitName={habitNameToEdit}
            habitID={idToEdit}
            onSave={handleEditHabit}
            onClose={closeEditDialog}
          />
        )}
      </div>
    </div>
  );
}