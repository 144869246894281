import React from 'react';
import './DeleteConfirmationDialog.css'; // Import the CSS for styling

export function DeleteConfirmationDialog({ habitName, onClose, onDelete }) {
  return (
    <div className="delete-dialog-overlay">
      <div className="delete-dialog">
        <h2>Are you sure you want to delete <br/> "{habitName}"?</h2>
        <div className="confirmation-buttons">
          <button onClick={onDelete} className="confirm-button">Yes, Delete</button>
          <button onClick={onClose} className="cancel-button">No, Cancel</button>
        </div>
      </div>
    </div>
  );
}